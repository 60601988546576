// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import 'https://fonts.googleapis.com/css?family=Lora|Abril+Fatface|Baloo+Paaji|Baloo+Tamma|Bitter|Cinzel|Cinzel+Decorative|Droid+Serif|Lobster|Monoton|Pacifico|Playfair+Display:900|Righteous|Shrikhand|Sigmar+One|Suez+One|Indie+Flower';
//::-webkit-scrollbar {
//    display: none;
//}
/**********************************some default class***************************************/
.main-color {
 background-color : #1b6d85 !important;
}

.bg-white {
  background: #fff;
  background: #f9f2f4;

}

.modal {
  background-color: #fff;
}

.margin-top-15 {
  margin-top: 15px;
}

.underline{
  border-bottom:2px black solid;
}

.border-1p-gray{
 border: 1px #c7ddef solid ; 
}


/*************************************base ********************************************/
* {
  margin: 0px;
  padding: 0px;
}

body {
  position: relative;
  overflow: auto;
}



.detail {
  .index-header {
    border-radius: 5px;
  }
  .panel {
    border: 3px #c7ddef solid !important;
    .panel-heading {
      background: #c7ddef !important;

      span {
        color: #1b6d85 !important;
      }
    }

  }
}

/***********************************Index**************************************/
.index-header {
  h1, h2, h3, h4, h5, h6, p, i {
     color: #fff !important;
   }

  //padding: 15px;
  color: #fff !important;
  font-family: 'Lato', sans-serif;
  .headline {
    font-family: 'Cinzel Decorative', cursive;
  }
  .group {
    color: #fff;
    font-family: 'Monoton', cursive;
  }

}

.index-body {
  min-height: 55vh;
  margin: 35px 25px;

}

.index-footer {
  padding: 15px;
  color: #fff;
  .paragraph {
    font-size: 14px;
    
    font-family: 'Baloo Tamma', cursive;

  }

}

/************************************home*************************************/
.home-body {
  margin-top: 100px;
  min-height: 78vh;
}

.home-header {
  //border: purple 5px solid ;
  border-radius: 0 0 15px 15px;
  .home-brand {

    background: #c7ddef;
  }
}

/***********************************main-panel**********************************/

.main-panel {
  margin-top: 100px;

  min-height: 78vh;
  overflow: hidden;

  thead tr th:nth-of-type(1), tbody tr td:nth-of-type(1) {
    background: #398439;
    color: white;
    position: absolute;

  }

  thead tr th:nth-of-type(2), tbody tr td:nth-of-type(2) {
    padding-left: 50px;
  }
}






